.custom-marker {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 50px;
}

.marker-label {
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%) translateY(16px);
	color: #0394e8;
	font-size: 16px;
	font-weight: 700;
	white-space: nowrap;
	transition: text-decoration 0.3s;
}

.marker-circle {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: rgba(3, 148, 232, 0.5);
	border: 2px solid #0394e8;
	transition: background-color 0.3s;
}

.custom-marker:hover .marker-circle {
	background-color: #0394e8;
}

.leaflet-control {
	border: none !important;
	width: 30px !important;
	opacity: 80% !important;
	background-color: white;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 20px 20px;
	width: 30px;
	height: 30px;
	cursor: pointer;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4) !important;
}

.fullscreen-icon {
	border-radius: 4px !important;
}

.fullscreen-icon:hover {
	opacity: 100% !important;
	background-color: white !important;
}

.leaflet-control:hover {
	opacity: 100% !important;
	background-color: white !important;
}

.leaflet-control-zoom-out {
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4) !important;
}

.leaflet-control-zoom-out:hover,
.leaflet-control-zoom-in:hover {
	background-color: white !important;
}

.last-known-position {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	background-color: white;
	padding: 5px 10px;
	z-index: 1000;
	opacity: 80%;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	margin-top: 10px;
	font-size: 14px;
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	text-align: center;
}
