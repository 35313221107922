@import url('./assets/fonts.css');

html,
body,
#root {
	min-height: 100vh;
	height: fit-content;
	background-color: #fafafa;
	font-family: 'Inter', sans-serif;
}

* {
	box-sizing: border-box;
}

body {
	display: flex;
	flex-direction: column;
}
