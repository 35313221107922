.transparent-grey {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 70%;
	z-index: 5000;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 20px;
	user-select: none;
	text-align: center;
	padding: 20px;
}
